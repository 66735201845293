<template>
  <div>
    <main class="page__container">
      <div class="container">
        <el-row justify="space-between" :gutter="20">
          <el-col :span="24">
            <h2 class="page__title">
              Оператор:
              <span style="color: black">{{ id }}</span>
            </h2>
          </el-col>
        </el-row>
        <el-form ref="form" label-position="top" :rules="rules" :model="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Название" prop="operator_name">
                <el-input v-model="form.operator_name" size="small" placeholder="Название" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Домен для приложений" prop="short_name">
                <el-input v-model="form.short_name" size="small" placeholder="operator-1" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Домен" prop="domain">
                <el-input v-model="form.domain" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Email техподдержки" prop="email">
                <el-input v-model="form.email" size="small" placeholder="Email" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Токен telegram-бота" prop="bot_token">
                <el-input id="bot_token" v-model="form.bot_token" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Номер телефона техподдержки" prop="support_phone">
                <el-input id="support_phone" v-model="form.support_phone" size="small" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-form-item>
              <el-button @click="showContacts = !showContacts" size="mini">
                Дополнительные контакты в мессенджерах
                <i
                  class="el-icon-right"
                  v-bind:class="showContactsIcon"
                />
              </el-button>
            </el-form-item>
          </el-row>
          <el-collapse-transition>
            <div v-show="showContacts">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Whatsapp" prop="support_whatsapp">
                    <el-input id="support_whatsapp" v-model="form.support_whatsapp" size="small" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Telegram" prop="support_telegram">
                    <el-input id="support_telegram" v-model="form.support_telegram" size="small" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Viber" prop="support_viber">
                    <el-input id="support_viber" v-model="form.support_viber" size="small" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Instagram" prop="support_instagram">
                    <el-input id="support_instagram" v-model="form.support_instagram" size="small" />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-transition>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Логин" prop="login">
                <el-input v-model="form.login" size="small" placeholder="Логин" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Пароль" prop="password">
                <el-button id="changePassword" @click="dialog = true" type="danger" size="small">
                  Изменить пароль
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5">
              <el-form-item label="Часовой пояс" prop="timezone">
                <el-input-number id="timezone" v-model="form.timezone" :min="-12" :max="12" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Заводить камеры по серийному номеру?" prop="has_cameras_serial">
                <el-switch
                  v-model="form.has_cameras_serial"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="several_orgs" label="Несколько юридических лиц?">
                <el-switch
                  v-model="form.several_orgs"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
              <el-form-item prop="can_use_cross_sharing" label="Может использовать функцию кросс-шаринга камер">
                <el-switch
                  v-model="form.can_use_cross_sharing"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-button class="page__active_button" type="primary" :loading="fetch" @click="submit">
            Сохранить
          </el-button>
          <el-button
            :disabled="form.status === `on`"
            class="page__active_button"
            type="danger"
            :loading="fetch"
            @click="onDelete"
          >
            Удалить
          </el-button>
          <el-button class="page__active_button" type="info" :loading="fetch" @click="onSwitch">
            {{ form.status === `on` ? "Заблокировать" : "Разблокировать" }}
          </el-button>
        </el-col>
      </el-row>
    </el-footer>
    <el-dialog id="dialogPassword" title="Изменить пароль" :visible.sync="dialog">
      <el-form
        id="formPassword"
        :model="formPassword"
        ref="formPassword"
        :rules="rulesPassword"
        label-position="right"
        label-width="150px"
      >
        <el-form-item label="Новый пароль" prop="password">
          <el-input id="password" v-model="formPassword.password" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button id="closeDialog" @click="dialog = false">Отменить</el-button>
        <el-button id="savePassword" type="primary" @click="savePassword">Изменить</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import { length, required, validatorNumber, validatorSpace } from "@/core/helpers/validators";
import { validatorUrl, validatorAllLatine } from "@/core/helpers/validators";

export default {
  name: "OperatorEdit",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fetch: false,
      dialog: false,
      rules: {
        short_name: [required(), validatorAllLatine, length(3, 200)],
        operator_name: [required(), length(3, 200)],
        domain: [required(), length(0, 300), validatorUrl],
        login: [required()],
        email: [required()],
        timezone: [required("number")],
        has_cameras_serial: [required("number")],
        several_orgs: [validatorNumber],
        can_use_cross_sharing: [validatorNumber],
      },
      formPassword: {
        password: "",
      },
      rulesPassword: {
        password: [required(), length(5, 100), validatorSpace, validatorAllLatine],
      },
      showContacts: false,
    };
  },
  computed: {
    ...mapState("operators", {
      form: (state) => state.operator,
    }),
    showContactsIcon() {
      if (this.showContacts) {
        return "el-icon-minus"
      } else {
        return "el-icon-plus"
      }
    }
  },
  created() {
    this.getOperator(this.id);
  },
  methods: {
    ...mapActions("operators", {
      getOperator: "GET_OPERATOR",
      deleteOperator: "DELETE_OPERATOR",
      updateOperator: "UPDATE_OPERATOR",
      switchStatus: "SWITCH_STATUS",
    }),
    submit() {
      this.$confirm("Вы точно хотите сохранить изменения?", "Подтверждение", {
        confirmButtonText: "Сохранить",
        type: "warning",
      })
        .then(() => {
          let formIsValid;
          this.$refs["form"].validate((status) => {
            formIsValid = status;
          });

          if (!formIsValid) return false;

          this.loading = true;
          const newOperator = Object.assign({}, this.form);
          const id = parseInt(this.id, 10);
          this.updateOperator({ id, newOperator })
            .then(() => {
              this.$router.push({ name: "operators", params: { page: 1 } });
              this.$message({
                message: "Изменения сохранены",
                type: "success",
              });
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: error.response.data.error
              })
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Отмена",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSwitch() {
      this.loading = true;
      const id = parseInt(this.id, 10);
      this.switchStatus({
        id,
        status: this.form.status === "on" ? "lock" : "on",
      })
        .then(() => {
          this.$router.push({ name: "operators", params: { page: 1 } });
          this.$message({
            message: this.form.status === `on` ? "Оператор заблокирован" : "Оператор разблокирован",
            type: "success",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDelete() {
      this.$confirm("Вы точно хотите удалить оператора?", "Подтверждение", {
        confirmButtonText: "Удалить",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          const id = parseInt(this.id, 10);
          this.deleteOperator(id)
            .then(() => {
              this.$router.push({ name: "operators", params: { page: 1 } });
              this.$message({
                message: "Оператор успешно удален",
                type: "success",
              });
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: error.response.data.error,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Отмена удаления",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    savePassword() {
      this.$refs["formPassword"].validate((isValid) => {
        if (!isValid) return false;
        this.dialog = false;
        this.$set(this.form, "password", this.formPassword.password);
      });
    },
  },
};
</script>

<style scoped>
.page_container {
  padding: 20px;
}
</style>
